import Head from 'next/head'

import '../app/styles/globals.css'
import type { AppProps } from 'next/app'

function MyApp({ Component, pageProps }: AppProps) {
  return <>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta name="google-site-verification" content="bpP5TjlbsOKLjpFcDJ1xhNxkUxDSste2K14lXIHNtsA" />
      </Head>  
    <Component {...pageProps} />
  </>
}
export default MyApp
